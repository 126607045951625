#questions {
    display: flex;
    flex-direction: column;
    max-width: 978px;
    margin: auto;
    form {
        display: flex;
        flex-direction: column;
        > label {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .btn-standard {
        margin-left: auto;
    }
}
#blocked {
    padding: 25px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: $box-shadow;
    h1 {
        margin-top: 0;
    }
}
.selects {
    overflow-y: auto;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    counter-reset: select;
    .sub-details {
        margin-left: auto;
        display: flex;
        > span {
            width: 110px;
            margin: 0 5px;
            text-align: center;
            color: $font-color;
            &:first-child {
                margin-left: auto;
                padding-left: 5px;
            }
            &:last-child {
                margin-right: 20px;
            }
        }
    }
    > .select {
        display: flex;
        align-items: center;
        transition: all 0.5s ease;
        padding: 10px 15px;
        &:not(:first-child) .an .sub-details {
            display: none;
        }
        &:before {
            counter-increment: select;
            content: counter(select);
            margin-right: 15px;
            color: $font-color;
            width: 18px;
        }
        &:hover {
            background-color: $background-color-hover;
        }
        > .label {
            font-weight: bold;
            margin-right: auto;
            margin-bottom: 0;
            margin-top: 0;
            color: $font-color-dark;
        }
        .an {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: auto;
            width: 100%;
            .sub-details > span:last-child {
                margin-right: 0;
            }
        }
        .to-select {
            display: flex;
            margin-left: auto;
            > .number {
                padding: 0 10px;
                margin: 5px;
                background-color: $main-color-light;
                color: $main-color-dark;
                width: 50px;
                height: 30px;
                font-size: 18px;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all 0.5s ease;
            }
        }
    }
    .label {
        max-width: 600px;
        line-height: 1.3;
        width: 100%;
        margin-right: 25px;
    }
}
.selects > .select .to-select > .number:hover,
.selects > .select .to-select > .number.active {
    border: 1px solid transparent;
    background-color: $main-color;
    color: white;
}