@import '../../styles/colors.scss';

ul#companies-list {
    list-style: none;
    padding: 25px;
    .details-title {
        h3 {
            margin: 0;
        }
        h4 {
            margin: 5px 0 0 0;
            color: $color-gray;
            font-weight: normal;
        }
    }
    > li {
        position: relative;
        .btn-remove {
            position: absolute;
            right: 0;
        }
    }
}
ul h3 {
    color: $main-color;
}
ul#companies-list > li {
    width: 100%;
}
ul#companies-list > li > a {
    border-bottom: 2px solid $main-color;
    padding-bottom: 15px;
    padding-top: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    text-decoration: none;
    > img {
        width: 40px;
        margin-right: 10px;
    }
}
ul#companies-list > li > a > button {
 margin-left: auto;
 margin-bottom: 0;
 margin-top: 0;
}
ul > li,
.employees-list > li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
}
ul > li > button {
    margin-left: 15px;
}
.employees-list {
    width: 100%;
    transition: all 0.5s ease;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    display: block;
    padding: 0 25px;
    margin-top: 15px;
    border: 1px solid transparent;
    background-color: $color-white;
    > li {
        padding: 15px 0;
        &:not(:first-child) {
            border-top: 1px solid $color-gray;
        }
        > img {
            width: 34px;
            margin-right: 10px;
            margin-left: 10px;
        }
    }
}
.employees-list.open {
    border-color:$color-gray;
    padding: 25px;
    margin-top: 15px;
    overflow-y: auto;
}
.employees-list > li .user-name {
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
}
.employees-list > li .url {
    position: relative;
    margin-left: auto;
}
.employees-list > li {
    position: relative;
    .link.blocked {
        background-color: $color-light-gray !important;
        img {
            filter: grayscale(10) brightness(3);
        }
    }
    .link,
    .block,
    .delete {
        display: flex;
        background-color:$main-color-light;
        margin-right: 10px;
        width: 44px;
        height: 44px;
        padding: 9px;
        cursor: pointer;
        position: relative;
        cursor: pointer;
        color: $main-color;
        justify-content: center;
        align-items: center;
        &:not(.link):not(.delete) {
            margin-left: auto;
        }
        > img {
            pointer-events: none;
            display: block;
            width: 25px;
            height: 25px;
        }
        & + button {
            margin-left: 0;
        }
    }
    .delete {
        background-color: $error-bg;
        > svg {
            fill: white;
        }
    }
    .answer {
        position: absolute;
        right: 40%;
        padding: 3px 15px;
    }
}
.employees-list > li .link:after {
    transition: all 0.5s ease;
    content: attr(data-link);
    display: block;
    position: absolute;
    opacity: 0;
    width: 200px;
    background-color: rgb(75, 75, 75);
    color: white;
    padding: 5px;
    max-width: 200px;
    transform: translate(-90px, 25px);
    word-break: break-all;
    text-align: center;
    z-index: 999;
    pointer-events: none;
    font-size: 14px;
    z-index: -1;
}
.employees-list > li .block:after {
    transition: all 0.5s ease;
    content: 'Link blockieren';
    display: block;
    position: absolute;
    opacity: 0;
    width: 200px;
    background-color: rgb(75, 75, 75);
    color: white;
    padding: 5px;
    max-width: 200px;
    transform: translate(-90px, 25px);
    word-break: break-all;
    text-align: center;
    pointer-events: none;
    z-index: -1;
    font-size: 14px;
}

.employees-list > li .link:hover:after {
    opacity: 1;
    transform: translate(-90px, 15px);
    z-index: 999;
}
.employees-list > li .block:hover:after {
    opacity: 1;
    transform: translate(-90px, 15px);
    z-index: 999;
}
.employees-list > li .link:before {
    transition: all 0.5s ease;
    content: 'Kopiert!';
    display: block;
    position: absolute;
    color: white;
    opacity: 0;
    background-color: rgb(36, 202, 100);
    padding: 5px;
    width: 60px;
    transform: translate(-25px, -55px);
    word-break: break-all;
    text-align: center;
    z-index: 999;
    pointer-events: none;
}
.employees-list > li .link.copied:before {
    transform: translate(-25px, -45px);
    opacity: 1;
}
.user-name > span {
    font-weight: bold;
    margin-right: 5px;
}
.user-name > span.email {
    font-weight: normal;
    margin-right: 5px;
    width: 100%;
    color: #b1b1b1;
    margin-top: 0;
}
.employees-list > li > p {
    margin: 0;
    padding: 10px 25px;
    color: $color-white;
    background-color: rgb(251, 155, 64);
}
.employees-list > li > p.answered {
    background-color: rgb(36, 202, 100);
}
.form {
    transition: all 0.5s ease;
    height: 0;
    overflow: hidden;
}
.form.open {
    height: 75px;
}
.form > form {
    display: flex;
    align-items: center;
    width: 100%;
}
.form > form > label {
    display: flex;
    flex-direction: column;
    margin: 10px;
}
label > b {
    margin-bottom: 5px;
}
label > input {
    padding: 5px;
}
.form > form > button {
    margin-left: auto !important;
}
.employee {
    position: relative;
}

#statistics {
    width: 978px;
    margin: 100px auto;
}
.result-box {
    margin-top: 50px;
    ul {
        display: flex;
        justify-content: center;
        padding: 0;
        > li {
            margin: 0 25px;
            display: flex;
            flex-direction: column;
            > p {
                margin-top: 0;
                margin-bottom: 0;
                color: $color-gray;
                font-size: 24px;
                > span {
                    font-weight: bold;
                    font-size: 24px;
                }
            }
        }
    }
}
.result-box > h3,
.individual-box > h3 {
    border-bottom: 2px solid $color-gray;
    font-size: 24px;
    padding-bottom: 15px;
}
.result-box ul > li {
    > h3 {
        font-size: 45px;
        margin: 0;
    }
}
.A p > span,
.A > h3 {
    color: $color-a;
}
.B p > span,
.B > h3 {
    color: $color-b;
}
.C p > span,
.C > h3 {
    color: $color-c;
}
.D p > span,
.D > h3 {
    color: $color-d;
}
#statistics h2 {
    color: $color-gray;
}
.individual-box {
    position: relative;
    display: block;
    width: 978px;
    height: 100vh;
    margin: 50px auto 100px auto;
}
#statistics .radar {
    width: 500px;
    height: 500px;
    transform: rotate(45deg) translate(-65%, 5%);
    position: absolute;
    z-index: 999;
    display: block;
    transition: all 0.5s ease;
    top: 52%;
    left: 50%;
}
#statistics .radar .radar-stats {
    position: relative;
    left: 6px;
    top: -4px;
    transform-style: preserve-3d;
    transform: rotate3d(1, 1, 0, 180deg) scale(1.12);
    min-height: 500px !important;
}
#statistics .radar img {
    position: absolute;
    width: 100%;
    height: 100%;
}
.a,
.b,
.c,
.d {
    font-weight: bold;
    margin: 5px !important;
}
.procentages ul {
    width: 978px;
    height: calc(100vh - 200px);
    position: relative;
    margin: 0;
    display: block;
    top: 100px;
}
.procentages ul > li > h3 {
    margin: 0;
    font-size: 24px;
    color: $font-color;
}
.procentages ul > li > p {
    color: $color-gray;
}
.procentages ul > li > p,
.procentages ul > li > p > span {
    font-size: 18px;
    margin: 5px 0;
}
.procentages ul > li {
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 80px;
}
.procentages ul > .pr-a {
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% - 300px));
    align-items: center;
}
.procentages ul > .pr-d {
    right: 50%;
    top: 50%;
    transform: translate(calc(50% + 350px), -50%);
    align-items: flex-start;
}
.procentages ul > .pr-b {
    left: 50%;
    top: 50%;
    transform: translate(calc(-50% - 350px), -50%);
    align-items: flex-end;
}
.procentages ul > .pr-c {
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, calc(50% + 300px));
}
.descriptions {
    position: absolute;
    width: 978px;
    height: 80vh;
    margin-top: 10vh;
}
.descriptions > ul {
    padding: 0;
    margin: 0;
}
.descriptions ul.desc-list-a {
    position: absolute;
    top: 0;
    left: 0;
}
.descriptions ul.desc-list-b {
    position: absolute;
    bottom: 0;
    left: 0;
}
.descriptions ul.desc-list-c {
    position: absolute;
    bottom: 0;
    right: 0;
}
.descriptions ul.desc-list-d {
    position: absolute;
    top: 0;
    right: 0;
}
.descriptions ul.desc-list-d > li,
.descriptions ul.desc-list-c > li {
    justify-content: flex-end;
}
.descriptions ul.desc-list-a li.big,
.a {
    color: $color-a;
}
.descriptions ul.desc-list-b li.big,
.b {
    color: rgb(1 172 59);
}
.descriptions ul.desc-list-c li.big,
.c {
    color: rgb(224 16 88);
}
.descriptions ul.desc-list-d li.big,
.d {
    color: rgb(237 204 6);
}
.descriptions ul li:not(.big) {
    margin: 5px 0;
}
.descriptions ul li.big {
    font-size: 45px;
    font-weight: bold;
}
#pass-form {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 25px;
    background-color: white;
    max-width: 450px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    p {
        color: $font-color;
        margin-top: 0;
    }
    label {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
        span {
            color: $font-color;
        }
    }
    button {
        margin-left: auto;
        margin-right: 0;
    }
    .checkbox {
        display: flex;
        flex-direction: unset;
        align-items: baseline;
        input {
            margin-top: 5px;
            margin-right: 15px;
        }
        p {
            color: $font-color;
            a {
                color: $main-color;
            }
        }
    }
}