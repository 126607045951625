@media screen and (max-width: 375px) {
  html > body {
    #app > div {
      &#statistics {
        .individual-box .radar {
          transform: rotate(45deg) translate(-20%, -15%) scale(1.2) !important;
          position: absolute !important;
          z-index: 999 !important;
          display: block !important;
          transition: all 0.5s ease !important;
          top: 42% !important;
          left: 16% !important;
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  html > body {
    #app > div {
      &#statistics {
        .individual-box .radar {
          transform: rotate(45deg) translate(-20%, -15%) scale(1) !important;
          position: absolute !important;
          z-index: 999 !important;
          display: block !important;
          transition: all 0.5s ease !important;
          top: 40% !important;
          left: 10% !important;
        }
      }
    }
  }
}
@media screen and (max-width: 978px) {
  html > body {
    padding: 15px;
    width: 100vw;
    height: calc(100vh - 55px);
    box-sizing: border-box;
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.3rem;
    }
    h3 {
      font-size: 1rem;
    }
    #header {
      margin: -15px;
      height: 70px;
    }
    #app {
      display: flex;
      flex-direction: column;
      padding-bottom: 25px;
    }
    #app > div {
      width: calc(100vw - 30px);
      height: 100%;
      overflow-y: auto;
      margin-top: 15px;
      max-height: calc(100vh - 150px);
      .btn-standard {
        height: 44px;
        margin-bottom: 0;
      }
      &#answer {
        .btn-print {
          position: relative !important;
          top: 0 !important;
          margin-top: 15px;
        }
        .pre-title {
          padding-top: 15px !important;
        }
      }
      &#dashboard {
        > button {
          margin-left: 0;
        }
        .form.open {
          height: 141px;
        }
        #add-company-form {
          background-color: white;
          flex-direction: column;
          label {
            width: calc(100% - 20px);
          }
        }
        #companies-list {
          padding: 0;
        }
      }
      #pass-form {
        padding: 15px;
        margin-top: 35px;
        width: calc(100% - 30px);
        margin-bottom: 0;
        > h1 {
          margin-bottom: 15px;
          margin-top: 0;
        }
        .checkbox {
          margin-bottom: 15px;
          p {
            line-height: 1;
          }
        }
        .btn-accept {
          margin-bottom: 0;
        }
      }
      &#questionnaire {
        .search {
          width: 100%;
          margin-bottom: 15px;
          margin-top: 25px;
        }
        .options {
          padding: 0;
          flex-direction: column-reverse;
          h3 {
            font-size: 20px;
            margin-right: 20px;
          }
          .toggle-btn {
            transform: scale(1.5);
            margin-right: auto;
          }
          .url-link {
            width: 100%;
            margin: 15px 0;
            textarea {
              font-size: 16px;
              height: 52px;
            }
          }
        }
        .employees-list {
          .email {
            margin: 15px 0;
            padding-left: 0;
          }
          .btn-standard {
            width: 100%;
            max-width: initial;
            margin: 0;
          }
        }
      }
      &#questions,
      &#second-questions {
        padding: 15px;
        overflow-x: hidden;
        .selects {
          overflow-y: initial;
          margin: 15px -30px;
          width: 100vw;
          .sub-details {
            display: flex !important;
            width: 100% !important;
            > span {
              margin-right: 0 !important;
              padding: 0 !important;
              margin-top: 10px !important;
              &:first-child {
                text-align: left !important;
              }
              &:last-child {
                margin-left: auto !important;
              }
            }
          }
          .select {
            margin-bottom: 15px;
            flex-direction: column;
            align-items: initial;
            &:hover {
              background-color: transparent;
            }
            &:before {
              display: none;
            }
            .number {
              padding: 0;
              width: 60px;
              height: 60px;
              &:first-child {
                margin-left: 0;
              }
            }
            .label {
              line-height: 1;
            }
            .to-select {
              width: 100%;
            }
          }
        }
        > .pre-title,
        > h1,
        > p {
          margin: 15px -15px;
          width: calc(100vw - 30px);
          padding-top: 0;
        }
        > button {
          margin-right: -10px;
          margin-bottom: -15px;
        }
      }
      &#statistics {
        margin-bottom: 0;
        overflow-x: hidden;
        > .result {
          padding-top: 15px;
          .btn-print {
            margin-top: 15px;
          }
        }
        .pre-title {
          padding-top: 0;
          margin-top: 20px;
        }
        .result-box {
          margin-top: 0;
          ul {
            flex-wrap: wrap;
            align-items: initial;
            justify-content: initial;
            li {
              width: calc(100% / 2);
              margin: 0;
              > p,
              > p > span {
                font-size: 1rem;
              }
              > h3 {
                font-size: 3rem;
              }
            }
          }
        }
        .individual-box {
          width: calc(100vw - 30px);
          margin-top: 0;
          .descriptions {
            margin-top: 0;
            width: calc(100vw - 30px);
            height: calc(100vh - 100px);
            ul {
              > li {
                &:not(.big) {
                  font-size: 12px;
                }
              }
            }
          }
          .radar {
            width: 250px;
            height: 250px;
            transform: rotate(45deg) translate(-20%, -15%) scale(1.5);
            position: absolute;
            z-index: 999;
            display: block;
            transition: all 0.5s ease;
            top: 43%;
            left: 18vw;
            .radar-stats {
              left: 6px;
              top: -4px;
              transform: rotate3d(1, 1, 0, 180deg) scale(1.2);
              min-height: 250px !important;
              width: 250px;
              height: 250px;
            }
          }
          .procentages > ul {
            margin-top: 0;
            width: calc(100vw - 30px);
            padding: 0;
            position: relative;
            top: calc(100vh - 90px);
            margin: 0;
            display: flex;
            height: 100px;
            justify-content: center;
            > li {
              align-items: center;
              justify-content: center;
              display: flex;
              position: relative;
              transform: initial;
              right: initial;
              left: initial;
              bottom: initial;
              top: initial;
              height: auto;
            }
          }
        }
      }
      &#employees {
        .employees-list {
          display: flex;
          flex-direction: column;
          padding: 0;
          background-color: transparent;
          height: calc(100vh - 310px);
          overflow-y: auto;
          .employee {
            align-items: end;
            height: auto;
            min-height: 165px;
            padding: 15px;
            background-color: white;
            margin-bottom: 15px;
            box-shadow: $box-shadow;
            border: 0;
            &.answered {
              min-height: 225px;
            }
            > img {
              margin-left: 0;
            }
            .user-name {
              width: calc(100% - 65px);
              margin-bottom: 15px;
            }
            .answer {
              position: relative;
              right: 0;
              left: 0;
              padding: 10px 15px;
              margin-right: auto;
            }
            .link {
              margin-right: 0;
            }
            > .btn-standard {
              width: calc(100% - 55px);
              max-width: initial;
              margin: 15px 0 0 0;
              justify-content: center;
              & + .delete {
                margin-top: 15px;
                height: 44px;
              }
            }
            .delete {
              margin-left: auto;
              margin-right: 0;
            }
          }
        }
        .form.open {
          height: 231px;
        }
        #add-employee-form {
          background-color: white;
          flex-direction: column;
          label {
            width: calc(100% - 20px);
          }
        }
        > button {
          margin-left: 0;
        }
      }
    }
  }
}
