.default-list {
    padding: 0;
    max-height: calc(100vh - 320px);
    overflow: auto;
    li {
        background-color: $color-white;
        padding: 16px 12px;
        border-bottom: 1px solid $color-light-gray;
        transition: 250ms ease-in;
        &:hover {
            background-color: $background-color-hover;
        }
    }
    p {
        margin: 0;
        font-weight: bold;
    }
    span {
        color: $font-color-light;
    }
    a {
        text-decoration: none;
    }
    .btn-group {
        margin-left: auto;
        > svg,
        > a {
            width: 24px;
            height: 24px;
            cursor: pointer;
            color: $main-color;
            margin: 8px;
            transition: 250ms ease-in;
            &:hover {
                color: mix($main-color, $main-color-dark, 50%);
            }
        }
    }
}

.info-list {
    padding: 0;
    margin: 0;
    li {
        padding: 4px 12px;
        border-bottom: 1px solid $color-light-gray;
        flex-wrap: nowrap !important;
        a{
            color: $main-color;
            transition: 250ms ease-in;
            &:hover {
                color: mix($main-color, $main-color-dark, 50%);
            }
        }
        .info-title {
            margin: 8px 0;
            min-width: 120px;
            font-weight: bold;
        }
        .info-description {
            margin: 8px 0;
        }
    }
}