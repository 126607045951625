@import '../../styles/colors.scss';

#questionnaire {
    max-width: 1280px;
    margin: auto;
    > h2 {
        color: $main-color-dark;
        font-weight: bold;
    }
    .email {
        background-color: transparent;
        margin-left: auto;
        margin-right: 15px;
        text-decoration: none;
        color: $color-light-gray;
    }
    .search {
        padding: 10px 15px;
        font-size: 16px;
        border: 1px solid $color-light-gray !important;
        box-shadow: none;
        background-color: white;
    }
    h3,
    p {
        margin: 0;
    }
    h3 {
        color: $main-color-dark;
    }
    p {
        color: $color-light-gray;
    }
    ul {
        height: auto;
    }
    li {
        > svg {
            color: $main-color;
            font-size: 40px;
            margin-right: 15px;
        }
        .btn-standard {
            margin-right: 0;
            margin-left: 0;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .delete {
            margin-left: 15px;
        }
    }
}
#individual {
    h2 {
        color: $main-color-dark;
        font-weight: bold;
        margin-top: 0;
    }
    p {
        color: $main-color-dark;
    }
}
#individual-questions,
#second-questions {
    h2 {
        color: $main-color-dark;
        font-weight: bold;
        margin-top: 0;
    }
    p {
        color: $main-color-dark;
    }
}
#second-questions {
    max-width: 978px;
    margin: auto;
    > h2 {
        margin-top: 25px;
    }
    button {
        margin-left: auto;
        margin-top: 25px;
        margin-right: 20px;
    }
}
#answer {
    margin: 50px auto;
    max-width: 600px;
    h2, h3 {
        color: $main-color-dark;
        font-weight: bold;
        margin: 0;
        > span {
            font-weight: normal;
            font-size: 1.17rem;
            margin-left: 10px;
        }
    }
    h2 {
        margin-top: 25px;
    }
    ul {
        padding: 0;
        margin-bottom: 50px;
    }
    li {
        border-bottom: 2px solid $background-color-hover;
        padding: 15px 0;
    }
    .answer-score {
        font-size: 40px;
        margin-left: auto;
        margin: 0 0 0 auto;
        position: relative;
        top: -1px;
    }
}