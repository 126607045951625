@import './colors.scss';

.btn-standard,
.btn-toggle,
.btn-add,
.btn-accept {
    border: 0;
    background-color: $main-color;
    color: $color-white;
    cursor: pointer;
    transition: all 0.5s ease;
    padding: 10px 25px;
    margin: 10px;
    max-width: 200px;
    display: flex;
    align-items: center;
    text-decoration: none;
    svg {
        transition: all 0.5s ease;
        pointer-events: none;
    }
    &.open {
        svg {
            transform: rotate(45deg);
        }
    }
    &.disabled {
        cursor: not-allowed;
        background-color: $color-light-gray !important;
        color: white !important;
    }
}
button.disabled {
    cursor: not-allowed;
    background-color: $color-light-gray !important;
    color: white !important;
}
.btn-standard:hover {
    background-color: darken($color: $main-color, $amount: 10%);
}
.back-btn {
    text-decoration: none;
    color: $main-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    background-color: transparent;
    border: 0;
    svg {
        font-size: 18px;
        stroke: $main-color;
    }
}
.btn-toggle {
    background-color: $color-white;
    border: 1px solid $main-color;
    color: $main-color;
    display: flex;
    align-items: center;
    svg {
        transition: all 0.5s ease;
        pointer-events: none;
    }
    &.open {
        svg {
            transform: rotate(180deg);
        }
    }
}

.btn-toggle:hover {
    background-color: $main-color-transparent;
    color: $color-white;
}
.btn-toggle.open:hover {
    background-color: $main-color-transparent;
}

.btn-add {
    border: 1px solid  $color-accept;
    background-color: $color-white;
    color:  $color-accept;
    svg {
        transition: all 0.5s ease;
        pointer-events: none;
    }
}
.btn-add:hover {
    background-color:  $color-accept;
    color: $color-white;
    border-color: transparent;
}
.btn-accept {
    background-color:  $color-accept;
    color: $color-white;
    border-color: transparent;
}
.btn-remove {
    color: $error-bg;
    cursor: pointer;
}
.btn-add.open {
    background-color:  $color-accept;
    color: $color-white;
    border-color: transparent;
    svg {
        transform: rotate(45deg);
    }
}
.btn-group {
    display: flex;
    align-items: center;
    > button {
        margin-right: 1rem;
    }
}

.toggle-btn {
    position: relative;
    max-width: 30px;
    margin: 15px 0;
    cursor: pointer;
    top: 1px;
    button {
        width: 30px;
        height: 15px;
        background-color: $background-color-hover;
        border: 0;
        box-shadow: none;
        border-radius: 10px;
        transition: background-color 0.5s ease;
    }
    &:after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background-color: white;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: -3px;
        transition: all 0.5s ease;
        transform: translateY(-50%);
        box-shadow: 0 0 5px -10px black;
        border: 1px solid $background-color-hover;
        margin-top: -1px;
    }
    &.toggled {
        button {
            background-color: $color-accept;
        }
        &:after {
            left: 15px;
        }
    }
}
.btn-secondary {
    text-decoration: none;
    color: $main-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    background-color: transparent;
    border: 1px solid $main-color;
    transition: all 0.3s ease;
    padding: 10px 25px;
    svg {
        font-size: 16px;
        stroke: $main-color;
        margin-right: 0.25rem;
        margin-top: -2px;
    }
    &:hover {
        opacity: 0.8;
    }
}
.btn-tertiary {
    text-decoration: none;
    color: $main-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    background-color: transparent;
    border: 0;
    transition: all 0.3s ease;
    padding: 10px 25px;
    svg {
        font-size: 16px;
        stroke: $main-color;
        margin-right: 0.25rem;
        margin-top: -2px;
    }
    &:hover {
        opacity: 0.8;
    }
}