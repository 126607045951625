@import './colors.scss';
@import './buttons.scss';
@import './questions.scss';
@import './login.scss';
@import './header.scss';
@import './modal.scss';
@import './responsive.scss';
@import './list.scss';
* {
  line-height: 1;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif !important;
}
body {
  padding: 35px;
  background-color: $background-color;
  min-height: 100vh;
  // overflow: hidden;
}
p, a, span, button {
  font-size: 16px;
  line-height: 1.5;
}
button, a {
  cursor: pointer;
}
p {
  color: $font-color;
}
h1 {
  color: $font-color-dark;
}
h2 {
  color: $color-light-gray;
  font-weight: normal;
}
h3 {
  color: $font-color;
}
.error-msg {
  padding: 10px;
  background-color: $error-bg;
  color: $color-white !important;
  text-align: center;
  margin: 15px auto;
}
label {
  b {
    color: $main-color;
  }
  input {
    border: 1px solid $main-color;
    color: $font-color-dark;
    font-size: 16px;
    padding: 8px 15px !important;
    &::placeholder {
      color: $main-color;
    }
  }
}
.pre-title{
  color: $main-color;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-top: 50px;
  &:before {
    content: '';
    display: block;
    width: 60px;
    height: 2px;
    background-color: $main-color;
    margin-right: 15px;
  }
}
#dashboard {
  max-width: 978px;
  margin: auto;
  padding-top: 50px;
  ul {
    list-style: none;
    padding: 0;
    margin-top: 50px;
    li a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $color-gray;
      font-weight: bold;
      padding: 15px;
      border-bottom: 2px solid $color-gray;
      width: 100%;
      cursor: pointer;
      transition: background-color 0.5s ease;
      h3 {
        margin: 0;
        color: $main-color-dark !important;
      }
      svg {
        color: $main-color;
        font-size: 40px;
        &:first-child {
          margin-right: 15px;
        }
        &:last-child {
          margin-left: auto;
        }
      }
      &:hover {
        background-color: $background-color-hover;
      }
    }
  }
}
.deactivated {
  color: $main-color-dark;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
}
.options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .btn-standard {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
  .open {
      display: flex;
      align-items: center;
      margin-left: 15px;
  }
  .url-link {
      width: 100%;
      display: flex;
      textarea {
          margin-left: auto;
          width: 100%;
          text-align: center;
          margin-top: 15px;
          padding: 10px;
          font-size: 20px;
          resize: none;
          height: 42px;
          border: 0;
          color: $background-color-hover;
          pointer-events: none;
          cursor: not-allowed;
          &.active {
              cursor: pointer;
              pointer-events: initial;
              color: $main-color-dark;
          }
      }
  }
  h3 {
      margin-right: 10px;
      margin-left: auto;
      line-height: 1px;
  }
  .toggle-btn {
      margin: 0;
  }
}
#add-employee-form {
  margin-top: 15px;
  label:first-child {
    margin-left: 0;
  }
  button {
    margin-right: 0;
  }
}