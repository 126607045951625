.modal {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    background-color: white;
    padding: 15px;
    box-shadow: $box-shadow;
    width: 100vw;
    &:after {
        content: '';
        display: block;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        position: fixed;
        left: 0;
        margin-top: 15px;
    }
    .event-preview {
        width: 200px;
        vertical-align: middle;
        margin-right: 15px;
    }
}