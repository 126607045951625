@import '../../styles/colors.scss';
#vwt, #event {
    .modal {
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 0 0 100vh 100vh rgba(0, 0, 0, 0.2);
        height: 100vh;
        h3 {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .form {
            height: calc(100vh - 200px);
            overflow: auto;
            .sub-title {
                font-weight: bold;
            }
            p {
                margin-bottom: .5rem;
                margin-top: 1.5rem;
            }
            textarea {
                max-width: 100%;
                width: 100%;
                min-height: 100px;
                border: 1px solid $main-color;
                color: $main-color-dark;
                font-size: 16px;
                padding: 8px 15px !important;
            }
            ul {
                padding-left: 0;
            }
            .react-datetime-picker__wrapper {
                padding: 0 15px;
                .react-datetime-picker__inputGroup {
                    > input {
                        padding-top: 8px;
                        padding-right: 0 !important;
                        height: auto;
                        &:not(:first-child) {
                            padding-left: 0 !important;
                        }
                        &.react-datetime-picker__inputGroup__year {
                            margin-right: 1rem;
                        }
                    }
                    > span {
                        color: #002a35;
                    }
                    .react-datetime-picker__inputGroup__input--hasLeadingZero {
                        margin-left: 0;
                    }
                }
            }
            .back-btn {
                font-weight: normal;
                font-size: 16px;
                cursor: pointer;
            }
            .logo-preview {
                height: 64px;
                margin-right: 15px;
                vertical-align: middle;
            }
        }
        &::after {
            display: none;
        }
        .calendar-icon {
            color: $main-color;
        }
    }
    .logo-preview {
        padding: 5px;
        width: 130px;
        min-height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid silver;
        cursor: pointer;
        transition: all 0.5s ease;
        &:hover {
            background-color: rgb(240, 247, 247);
        }
        p {
            margin: 0 0 10px 0 !important;
            text-align: center;
            font-size: 12px;
        }
        > img {
            width: 60px;
            height: 60px;
            border-radius: 5px;
            object-fit: contain;
            display: block;
        }
    }
    .image-input {
        display: flex;
        flex-wrap: wrap;
        width: 150px;
        height: 185px;
        > p {
            width: 100%;
        }
        > input {
            visibility: hidden;
        }
    }
}
#event {
    .event-header {
        display: flex;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 1rem;
        .logo {
            width: 70px;
            height: 70px;
            margin-right: 1rem;
            object-fit: contain;
        }
        .btn-tertiary {
            margin-left: auto;
        }
    }
    .event-registrations {
        margin-top: 2rem;
        max-height: calc(100vh - 560px);
        min-height: 200px;
        position: relative;
        overflow: auto;
    }
    .btn-csv {
        padding: 4px 12px;
        background-color: $main-color;
        color: white;
        border-radius: 3px;
        text-align: center;
        text-decoration: none;
        min-height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        margin-top: 2rem;
        font-size: 12px;
    }
    .registered-list {
        border-collapse: collapse;
        min-width: 1700px;
        width: 100%;
        th,
        td {
            text-align: left;
            padding:8px;
            height: 45px;
        }
        thead {
            th {
                position: sticky;
                top: 0;
                border-bottom: 1px solid #BABFC4;
                min-width: 120px;
                background-color: #e5e7e7;
            }
        }
        tbody {
            td {
                border-bottom: 1px solid #BABFC4;
                font-weight: normal;
                > .options {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    > svg {
                        color: $main-color;
                        margin-left: .5rem;
                        cursor: pointer;
                        transition: opacity 0.5s ease;
                        &:hover {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
}