#header {
    display: flex;
    align-items: center;
    padding: 15px 35px;
    margin: -35px -35px 0 -35px;
    background-color: white;
    img {
        display: block;
        width: 100px;
    }
    a:not(.logo) {
        color: $main-color;
        text-decoration: none;
        font-weight: bold;
        font-size: 18px;
        margin-left: auto;
        transition: all 0.5s ease;
        &:hover {
            color: $main-color-dark;
        }
    }
}