#login {
    display: flex;
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        input {
            margin: 10px;
            padding: 8px 15px;
            border: 1px solid $main-color;
            font-size: 16px;
            
            color: $main-color;
        }
    }
}