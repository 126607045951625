@page {
  @bottom-left {
    display: none;
  }
}
@media print {
  #header,
  .back-btn,
  .pre-title,
  .btn-print {
    display: none;
  }
  #statistics {
    margin: 0 auto;
    page-break-after: avoid;
    h1 {
      margin-top: 0;
    }
    .individual-box {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
    .radar {
      margin-top: -120px;
      page-break-after: avoid;

      //   margin-top: 4rem;
      //   transform: translate(-15rem, -10rem) rotate(45deg);
      transform: rotate(45deg) translate(-20rem, 3rem);
      .apexcharts-graphical .apexcharts-grid line {
        display: none;
      }
    }
    .procentages {
      //   transform: translateY(3rem);
      margin-top: -115px;

      > ul {
        top: 120px;
      }
    }
    .descriptions {
      margin-top: -50px;
      top: 120px;
      //   margin-top: 50px;
    }
  }
}
