$color-a: rgb(33 109 213);
$color-b: rgb(1 172 59);
$color-c: rgb(224 16 88);
$color-d: rgb(237 204 6);

$main-color: #018db0;
$main-color-light: #cce8ef;
$main-color-dark: #014758;
$main-color-transparent: rgba(1, 141, 176, 0.5);
$background-color: #f4f6f7;
$background-color-hover: #e6eaeb;
$color-accept: #24ca64;
$color-wip:rgb(251, 155, 64);

$font-color: #33555d;
$font-color-dark: #002a35;
$font-color-light: #393a4d;
$font-color-light-transparent: rgba(57, 58, 77, 0.6);

$error-bg: rgb(255, 96, 96);
$error-border: rgb(195, 10, 10);

$color-white: white;
$color-light-gray: #a2a2a2;
$color-gray: #80959a;

$box-shadow: 0 10px 20px -25px black;