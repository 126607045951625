@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

$header-bg-color: black;
$main-color-register: #B0976D;
$error-border: red;

#app {
    #registration,
    #registration-success {
        width: 100vw !important;
        min-height: 100vh !important;
        margin: -15px -15px 0 -15px !important;
        * {
            font-family: 'Playfair Display', serif !important;
            /* width */
            ::-webkit-scrollbar {
                width: 10px;
            }
            /* Track */
            ::-webkit-scrollbar-track {
                background: transparent;
            }
            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: rgb(39, 39, 39);
            }
            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: rgb(26, 26, 26);
            }
        }
        .header {
            background-color: $header-bg-color;
            height: 72px;
            display: flex;
            align-items: center;
            padding: 0 20px;
            position: fixed;
            width: 100%;
            z-index: 20;
            img {
                height: 56px;
                width: 56px;
            }
        }
        .body {
            margin-top: 72px;
            background-color: #353535;
            padding: 25px 15px;

            .hero {
                position: relative;
                height: calc(100vh - 72px);
                display: flex;
                align-items: flex-end;
                justify-content: center;
                overflow: hidden;
                margin: -25px -15px;
                > img {
                    position: absolute;
                    z-index: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: right;
                    top: 0;
                }
                .hero-content {
                    background-color: rgba(256, 256, 256, 0.8);
                    margin: 15px 15px 100px 15px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 25px 15px;
                    position: relative;
                    z-index: 1;
                    > img {
                        height: 64px;
                        border-radius: 5px;
                    }
                    > h2 {
                        line-height: 1.5;
                        color: #353535;
                        text-align: center;
                        font-size: 24px;
                    }
                    > h3 {
                        line-height: 1.5;
                        color: #353535;
                        font-size: 18px;
                        text-align: center;
                        margin-bottom: 0;
                    }
                    > h4 {
                        line-height: 1.5;
                        color: #353535;
                        font-size: 14px;
                        text-align: center;
                        margin: 0;
                    }
                    a {
                        color: $main-color-register;
                    }
                }
            }
            .registration-form.offline {
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                h2 {
                    font-size: 56px;
                    color: white;
                    text-align: center;
                }
                h3 {
                    font-size: 24px;
                    color: white;
                    text-align: center;
                    line-height: 1.5;
                }
            }
            .slogans-container {
                padding: 25px 15px;
                h2 {
                    color: #FFFFFF;
                    text-align: center;
                    font-size: 36px;
                }
                p {
                    color: #FFFFFF;
                    text-align: center;
                }
            }
            .greetings-container {
                background-color: rgba(256, 256, 256, 0.8);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 25px 15px;
                h2 {
                    margin-top: 0;
                    color: #353535;
                    text-align: center;
                    font-size: 36px;
                }
                p {
                    color: #353535;
                    text-align: center;
                    margin: 0;
                }
            }
            form {
                * {
                    font-family: 'Lato', sans-serif !important;
                }
                > p {
                    color: white;
                    font-size: 18px;
                    &.description {
                        color: #BABFC4;
                        font-size: 14px;
                    }
                }
                label {
                    width: 100%;
                    position: relative;
                    input {
                        width: 100%;
                        border-radius: 5px;
                        font-size: 16px;
                        &.has-error {
                            border: 1px solid red;
                        }
                    }
                    p {
                        color: white;
                        text-transform: uppercase;
                        font-size: 16px;
                        margin-bottom: 0;
                    }
                    &.checkbox {
                        display: flex;
                        position: relative;
                        margin-top: 1rem;
                        > input {
                            width: 20px;
                            min-width: 20px;
                            height: 20px;
                            margin-right: 1rem;
                            background-color: transparent;
                            z-index: 0;
                            visibility: hidden;
                            &:checked + div {
                                background-color: $main-color-register;
                                border-color: $main-color-register;
                                > svg {
                                    z-index: 3;
                                    color: white;
                                    font-size: 16px;
                                    visibility: visible;
                                }
                            }
                        }
                        > p {
                            margin-top: 0;
                            text-transform: none;
                        }
                        > div {
                            display: flex;
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            margin: 3px;
                            z-index: 2;
                            border: 1px solid white;
                            border-radius: 3px;
                            justify-content: center;
                            align-items: center;
                            > svg {
                                color: white;
                                font-size: 16px;
                                visibility: hidden;
                            }
                        }
                    }
                    &.radio-select {
                        display: flex;
                        position: relative;
                        margin-top: 1rem;
                        > input {
                            width: 15px;
                            height: 15px;
                            margin-right: 1rem;
                            position: relative;
                            z-index: 0;
                            visibility: hidden;
                            &:checked + div {
                                background-color: $main-color-register;
                                border-color: $main-color-register;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                &::after {
                                    content: '';
                                    display: block;
                                    width: 6px;
                                    height: 6px;
                                    background-color: white;
                                    border-radius: 50%;
                                }
                            }
                        }
                        > div {
                            display: block;
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            margin: 3px;
                            z-index: 2;
                            border: 1px solid white;
                            border-radius: 50%;
                        }
                        > p {
                            margin-top: 0;
                            text-transform: none;
                        }
                    }
                    a {
                        text-decoration: none;
                        color: $main-color-register;
                    }
                }
                .select-input {
                    position: relative;
                    background-color: white;
                    max-height: 0;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 0 8px;
                    border-radius: 5px;
                    min-height: 37px;
                    width: 100%;
                    border: 1px solid transparent;
                    &.has-error {
                        border-color: red;
                    }
                    > p {
                        margin: 0;
                        color: #3d3d3d;
                    }
                    > svg {
                        margin-left: auto;
                    }
                }
                .select-box {
                    position: relative;
                    background-color: white;
                    max-height: 0;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 0 8px;
                    overflow: hidden;
                    border-radius: 5px;
                    transition: all 0.5s ease;

                    &.open {
                        max-height: 250px;
                        overflow: auto;
                        height: 100%;
                    }
                    > .selected-country {
                        width: calc(100% - 30px) !important;
                    }
                    > li {
                        height: 37px;
                        width: 100%;
                    }
                    > svg {
                        width: 30px;
                    }
                }
                > button {
                    background-color: $main-color-register;
                    border: 1px solid transparent !important;
                    padding: 5px 15px;
                    text-align: center;
                    text-transform: uppercase;
                    color: white;
                    margin: 1rem auto;
                    display: block;
                }
                hr {
                    margin: 1rem 0;
                }
                input {
                    border-color: transparent;
                }
                .datepicker {
                    width: 100%;
                    height: 37px;
                    &.has-error > div {
                        border-color: $error-border;
                    }
                    > div {
                        padding: 0 15px !important;
                        background-color: white;
                        width: 100%;
                        border-radius: 5px;
                        font-size: 16px;
                        border: 1px solid transparent;
                    }
                    input::placeholder {
                        color: #272727;
                    }
                    input:not(.react-datetime-picker__inputGroup__input) {
                        padding: 0 15px !important;
                    }
                    .react-datetime-picker__inputGroup__input {
                        padding: 0 !important;
                        margin-left: 0;
                    }
                    .calendar-icon {
                        color: #272727;
                    }
                }
            }
        }
    }
}
#root #app > #registration-success {
    > .body {
        flex-direction: column;
        align-items: center;
        height: 100vh;
        padding-left: 0;
        padding-right: 0;
        overflow: auto;
        > div.hero {
            height: 70vh;
            min-height: 350px;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin: 0 !important;
            > .hero-content {
                background-color: transparent;
                margin: 0;
                > h2 {
                    color: white;
                }
            }
            > img {
                width: 100%;
                left: 0;
                opacity: 0.2;
            }
        }
        .success-message {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 25px;
            margin-top: 50px;
            h2, p {
                color: white;
                text-align: center;
                line-height: 1.5;
            }
        }
        .btn-primary-registered {
            background-color: $main-color-register;
            border: 1px solid transparent;
            padding: 5px 15px;
            text-align: center;
            text-transform: uppercase;
            color: white;
            margin: 1rem auto;
            display: block;
            text-decoration: none;
        }
    }
}
@media screen and (min-width: 978px) {
    #app {
        #registration,
        #registration-success {
            margin: -35px !important;
            .body {
                margin: 0;
                display: flex;
                padding-top: 72px;
                max-height: 100vh;
                overflow: hidden;
                padding-right: 0;
                > div {
                    width: 50%;
                    &.hero {
                        margin: 0 15px -25px -15px;
                        height: 100vh;
                    }
                    &.registration-form {
                        max-height: calc(100vh - 72px);
                        overflow: auto;
                    }
                    form {
                        display: flex;
                        flex-wrap: wrap;
                        > .sub-title,
                        > .description {
                            width: 100%;
                            padding: 0 15px;
                        }
                        > label:not(.radio-select):not(.checkbox) {
                            width: 50%;
                            padding: 0 15px;
                        }
                        .radio-select,
                        .checkbox {
                            margin: 15px 15px;
                        }
                        > hr {
                            width: 100%;
                            margin: 0 15px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 978px) {
    #root > #app {
        padding-bottom: 0;
        > #registration-success {
            margin: -15px !important;
            > .body {
                height: 100%;
                padding-top: 0;
                div.hero {
                    margin: -25px -15px;
                }
                div.success-message {
                    margin-top: 50px;
                }
            }
        }
        > #registration {
            > .body {
                > div.hero {
                    align-items: center;
                    > .hero-content {
                        margin-bottom: 15px;
                    }
                    > img {
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}